import axiosInstance from '@/plugins/axios'
export const getProjectList = () => {
  return axiosInstance.post('/aie/tripartite/video/device/web/project/list').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.code,
        label: item.name,
        origin: item,
      }
    })
  })
}
export const getRuleTypeList = () => {
  return axiosInstance.post('/aie/web/rule/type/list').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.ruleCode,
        label: item.ruleType,
        origin: item,
      }
    })
  })
}
export const getNewVehicleTypeList = () => {
  return axiosInstance.post('/aie/web/vehicle/enable/list').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.typeCode,
        label: item.typeValue,
        origin: item,
      }
    })
  })
}
export const getMaterialTypeList = () => {
  return axiosInstance
    .post('/aie/web/list', {
      type: 'MATERIAL_TYPE',
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.typeCode,
          label: item.typeValue,
          origin: item,
        }
      })
    })
}
export const getHinderTypeList = () => {
  return axiosInstance
    .post('/aie/web/list', {
      type: 'HINDER_TYPE',
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.typeCode,
          label: item.typeValue,
          origin: item,
        }
      })
    })
}
export const getSybList = () => {
  return axiosInstance.post('/aie/web/sybNotAppendAll').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.code,
        label: item.name,
        origin: item,
      }
    })
  })
}
export const getRuleRoleList = () => {
  return axiosInstance
    .post('/aie/web/list', {
      type: 'RULE_ROLE_CODE',
    })
    .then((res) => {
      return res.data.map((item) => {
        return {
          value: item.typeCode,
          label: item.typeValue,
          origin: item,
        }
      })
    })
}

export const getPretreatmentList = () => {
  return axiosInstance.post('/aie/web/truck/pretreatment/user/all').then((res) => {
    return res.data.map((item) => {
      return {
        value: item.pretreatmentCode,
        label: item.pretreatmentName,
        origin: item,
      }
    })
  })
}
