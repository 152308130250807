import $axios from '@/plugins/axios'
import jsFileDownload from 'js-file-download'
import { message } from 'ant-design-vue'
const parser = (data) => {
  return new Promise((resolve, reject) => {
    const tempBlob = new Blob([data], { type: 'application/json' })
    const reader = new FileReader()
    reader.onload = (e) => {
      const res = e.target?.result
      // 此处对fileReader读出的结果进行JSON解析
      // 可能会出现错误，需要进行捕获
      try {
        const json = JSON.parse(res)
        console.log(json)
        // 正常json数据格式
        reject(new Error(json?.message || '解析文档流失败'))
      } catch (err) {
        // 该异常为无法将字符串转为json
        // 说明返回的数据是一个流文件
        // 不需要处理该异常，只需要捕获即刻
        resolve(data)
      }
    }
    reader.readAsText(tempBlob)
  })
}
export const download = async (url, params, { filename = '', method = 'post', showError = true } = {}) => {
  try {
    const res = await $axios.request({
      method,
      url,
      params: method === 'post' ? {} : params,
      data: method === 'post' ? params : {},
      responseType: 'arraybuffer',
      interceptors: {
        response: false,
      },
      showError: false,
    })
    await parser(res.data)
    let name = filename

    if (!name) {
      const contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matched = contentDisposition.match(filenameRegex)
        if (matched) {
          name = decodeURIComponent(matched[1])
        }
      }
    }
    if (!name) {
      throw new Error('读取文件名失败')
    }
    jsFileDownload(res.data, name)
  } catch (e) {
    if (showError) {
      message.error(e.message)
    }
    throw e
  }
}
