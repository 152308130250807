<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['syb']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="车牌号">
              <a-input class="form-control" v-decorator="['plateNo']" placeholder="请输入" allowClear></a-input>
            </a-form-item>
            <a-form-item label="时间">
              <a-range-picker v-decorator="['date']" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="export" type="primary" @click="exportExcel">导出</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal :title="modal.title" v-model="modal.visible" class="modal-scroll full-screen" @ok="handleOk">
      <a-form class="flex-form a-form-mb-2" :form="modal.form">
        <div class="flex">
          <div class="mr-5" v-for="(item, index) in modal.data.photoList" :key="index">
            <auto-img
              :src="item"
              style="width: 300px; height: 300px"
              :preview="{ options: { initialViewIndex: index }, images: modal.data.photoList }"
            ></auto-img>
          </div>
        </div>
        <div class="mt-10">
          <a-form-item label="区域">{{ modal.data.sybName }}</a-form-item>
          <a-form-item label="项目">{{ modal.data.projectName }}</a-form-item>
          <a-form-item label="车牌号">{{ modal.data.plateNo }}</a-form-item>
          <a-form-item label="材料类别">{{ modal.data.materialTypeName }}</a-form-item>
          <a-form-item label="出场检查时间">{{ modal.data.passTime }}</a-form-item>
          <a-form-item label="是否载重">{{ modal.data.passLoad ? '是' : '否' }}</a-form-item>
          <a-form-item label="是否分包或政府车辆">{{ modal.data.governmentVehicle ? '是' : '否' }}</a-form-item>
          <a-form-item label="出场检查说明">{{ modal.data.remarks }}</a-form-item>
          <a-form-item label="操作人">{{ modal.data.operatorName }}</a-form-item>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getSybList } from '@/service/getData/aie'
import { download } from '@/service/file'
import moment from 'moment'

export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '区域',
        width: '8%',
        align: 'center',
        dataIndex: 'sybName',
      },
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '车牌号',
        width: '8%',
        align: 'center',
        dataIndex: 'plateNo',
      },
      {
        title: '材料类别',
        width: '8%',
        align: 'center',
        dataIndex: 'materialTypeName',
      },
      {
        title: '出场检查时间',
        width: '10%',
        align: 'center',
        dataIndex: 'passTime',
      },
      {
        title: '操作',
        align: 'center',
        width: '5%',
        customRender: (text, record) => {
          return (
            <div>
              <a
                onClick={() => {
                  this.checkRow(record)
                }}
              >
                详情
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
      projectList: [],
      sybList: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },
  methods: {
    getFormatedPageParams(pageNo, pageSize) {
      const params = this.formatTimeRangeParams(this.getPageParams(pageNo, pageSize), 'date', {
        format: 'YYYY-MM-DD',
      })
      params.status = 1
      return params
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post(
          '/aie/web/truck/pass/out/record/page',
          this.getFormatedPageParams(_pageNo, _pageSize)
        )
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async checkRow(row) {
      this.resetModal()
      this.modal.title = '出场检查详情'
      this.modal.visible = true
      this.modal.data = {
        ...row,
        photoList: row.photoList || [],
      }
    },
    handleOk() {
      this.modal.visible = false
    },
    exportExcel() {
      const params = this.getFormatedPageParams(this.pageNo, this.pageSize)
      download('/aie/web/truck/pass/out/record/export', params)
    },
  },
  async mounted() {
    this.loadSybList()
    await this.loadProjectList()
    const { projectCode, passDate, plateNo } = this.$route.query
    if (projectCode) {
      this.form.setFieldsValue({
        projectCode,
      })
    }
    if (passDate) {
      this.form.setFieldsValue({
        date: [moment(passDate), moment(passDate)],
      })
    }
    if (plateNo) {
      this.form.setFieldsValue({
        plateNo,
      })
    }
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
