<template>
  <a-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :showSearch="true"
    option-filter-prop="children"
    :filterOption="foPY"
    @search="handlerFilterOption"
    @popupScroll="handlerScroll"
    @blur="handlerSelected"
    @dropdownVisibleChange="handlerDropdown"
  >
    <a-select-option v-for="(item, index) in uniqFilterSelectOptions" :key="index" :value="item.value">
      {{ item.label }}
    </a-select-option>
  </a-select>
</template>

<script>
import _ from 'lodash'
import { pyFilter } from '@/utils/antd'
export default {
  name: '',
  components: {},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 父组件通过v-model实在父子组件数据选择同步
    value: {
      type: [Array, Number, String],
      default: undefined,
    },
    options: {
      // 下拉列表数据
      type: Array,
      default: () => [],
    },
    maxShowStrips: {
      // 初始化和每次滚动显示的最大条数
      type: Number,
      default: () => 15,
    },
  },
  data() {
    return {
      userSerach: '', // 用户搜索时的内容
      filterOptions: [], // 过滤用户输入后的option
      selected: undefined, // 选择的数据
    }
  },
  computed: {
    // 避免重复插入截取的数据
    uniqFilterSelectOptions() {
      const { filterOptions = [] } = this
      const optionsList = []
      return filterOptions.filter((item) => {
        const { value } = item || {}
        if (optionsList.includes(value)) {
          return false
        } else {
          optionsList.push(value)
          return true
        }
      })
    },
  },
  created() {},
  mounted() {},
  methods: {
    foPY(input, option) {
      const label = option.componentOptions.children[0]?.text || ''
      return pyFilter(input, label)
    },
    // 得到下拉列表的数据
    getSelectOptions(selected) {
      const { userSerach = '', options = [], filterOptions = [], maxShowStrips = 15 } = this
      if (!Array.isArray(options)) return
      let tempFilterOptions = userSerach
        ? options.filter((item) => {
            const { label = '' } = item || {}
            return pyFilter(userSerach, label)
          })
        : options
      if (tempFilterOptions.length > maxShowStrips) {
        tempFilterOptions = tempFilterOptions.slice(0, maxShowStrips)
      }
      // 判断用户是否有选择
      if (selected) {
        if (!selected) return
        // 从optons中过滤出当前选中的数据
        const selectedOptions = options.filter((item) => {
          const { value } = item || {}
          if (Array.isArray(selected) && selected.length > 0 && selected.includes(value)) {
            return true
          } else if (selected === value) {
            return true
          } else {
            return false
          }
        })
        selectedOptions.forEach((item) => {
          const option = _.find(filterOptions, { value: item.value })
          if (!option || !option.value) {
            tempFilterOptions.unshift(item)
          }
        })
      }
      this.filterOptions = tempFilterOptions
    },
    // 滚动条滚动，无限拼接数据
    handlerScroll: _.debounce(function () {
      const { options = [], maxShowStrips = 15, filterOptions = [] } = this
      const currindex = filterOptions.length
      const allIndex = options.length
      const nextPageIndex = currindex + maxShowStrips
      const newFilterOptions =
        nextPageIndex > allIndex ? options.slice(currindex, allIndex) : options.slice(currindex, nextPageIndex)
      this.filterOptions = filterOptions.concat(newFilterOptions)
    }, 200),
    // 用户改变搜索条件
    handlerFilterOption(value) {
      this.userSerach = value || ''
      this.getSelectOptions()
    },
    // 用户选择
    handlerSelected() {
      if (this.userSerach) {
        this.userSerach = ''
        this.getSelectOptions(this.selected)
      }
    },
    handlerDropdown(flag) {
      if (flag) this.getSelectOptions()
    },
  },
  filter() {},
  watch: {
    options: {
      handler() {
        this.getSelectOptions()
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(val) {
        if (!val) {
          this.selected = undefined
        } else {
          this.selected = val
          this.getSelectOptions(val)
        }
      },
    },
  },
}
</script>

<style lang="less" scoped></style>
